// import wave from './assets/wave.png';
import './App.css';
import MailchimpSubscribe from "react-mailchimp-subscribe"

function App() {

  const { REACT_APP_U, REACT_APP_ID } = process.env;

  const url = `https://liquidthought.us5.list-manage.com/subscribe/post?u=${REACT_APP_U}&amp;id=${REACT_APP_ID}`;

  const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div>
        {status === "sending" && <div style={{ color: "#FFFFFF" }}>sending...</div>}
        {status === "error" && (
          <div
            style={{ color: "#FFFFFF" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            style={{ color: "#FFFFFF" }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="input-group input-wrappper my-3">
         <input ref={node => (email = node)} type="email" className="form-control" placeholder="Email address" aria-label="Recipient's email" />
         <button className="btn btn-outline-secondary" type="button" onClick={submit}>Subscribe</button>
       </div>
      </div>
    );
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="wrapper">
          <h1 className="display-1 text-uppercase mb-0 font-rock-well">
            explorers
          </h1>
          <p className="sub-title">coming soon</p>
          <div className="row mt-1">
            <MailchimpSubscribe
              url={url}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                />
              )}
            />
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
